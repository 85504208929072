import { ChangeDetectionStrategy, Component, OnInit, } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class FooterComponent implements OnInit {

  currentYear: number

  constructor(
    public app: AppService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
  }

  routeToTerms(section: any) {
    if (section == 'terms') {
      this.router.navigate(['/' + this?.app?.languageCode + '/terms'], {
        queryParams: {
          'section': 'terms',
        }
      });
    } else if (section == 'privacy') {
      this.router.navigate(['/' + this?.app?.languageCode + '/terms'], {
        queryParams: {
          'section': 'privacy',
        }
      });
    }
  }

  routeToTechznone(){
    window.open("https://www.technzone.com/", '_blank')
  }

}

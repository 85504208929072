import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { environment } from "src/environments/environment";
import { isPlatformBrowser, isPlatformServer } from "@angular/common";
import { Router } from "@angular/router";
import { Meta, Title } from "@angular/platform-browser";

export class response {
  data: any;
  errorCode: number;
  errorMessage: string;
  success: boolean
}

@Injectable({
  providedIn: "root"
})
export class AppService {

  //ATTRIBUTES
  isLoading: boolean = false;

  // Handle LangCode ServerSide And ClintSide
  langServerSideCode = this?.router?.url?.includes('/ar/') ? 'ar' : 'en';
  langBrowserSideCode = localStorage.Language ? localStorage.Language : 'en'
  languageCode = isPlatformBrowser(this.platformId) ? this?.langBrowserSideCode : this.langServerSideCode
  // Handle LangCode ServerSide And ClintSide

  isPlatformBrowserVar = isPlatformBrowser(this.platformId) ? isPlatformBrowser(this.platformId) : false
  isPlatformServerVar = isPlatformServer(this.platformId) ? isPlatformServer(this.platformId) : false

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private meta: Meta,
    private title: Title,
  ) { }

  showLoading() {
    this.isLoading = true
  }
  dismissLoading() {
    this.isLoading = false
  }

  dismissAfter(){
    setTimeout(() => {
      this.dismissLoading();
    }, 600);
  }

  getImageFile = (name: string) => name ? environment.imageBaseUrl + name : "assets/img/doctors/book-doctor-03.jpg"

  getUserImage(name: string) {
    if (name) {
      return environment.imageBaseUrl + name
    } else if (!name) {
      if (this?.getUserData()?.gender == 1) {
        return "assets/img/icons/male.png"
      } else if (this?.getUserData()?.gender == 2) {
        return "assets/img/icons/female.png"
      }
    }
  }

  getUserData = (): any => JSON.parse(localStorage.getItem(environment.appName + "UserData"));

  getUserLanguage = (): any => localStorage.Language;

  getUserDire = (): any => localStorage.Direction;

  isProbablyArabic(str: string): boolean {
    var Character
    for (var i = 0; i < 5; i++) {
      let c = str.codePointAt(i);
      if (c >= 0x0600 && c <= 0x06E0)
        return true;
    }
    return false;
  }

  updateMetaService(content) {
    this.meta.updateTag({ name: 'description', content: `${content}` });
  }

  updateTitleService(content) {
    this.title.setTitle(`${content}`)
  }


} 
<footer class="footer pt-5 pb-3" data-aos="fade-in" data-aos-duration="800">
	<!-- Footer Top -->
	<div class="footer-top mb-Res">
		<div class="container">
			<div class="row">
				<div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-5">

					<!-- Footer Widget -->
					<div class="footer-widget footer-about">
						<div class="footer-logo mb-4">
							<img src="assets/img/navbar/whiteLogo.png" alt="logo" loading="lazy">
						</div>

						<p class="secondMainP33 text-white mb-0" [ngClass]="router?.url?.includes('/ar/') ? 'font-Careem' : ''">{{"strategy.regNumb"|translate }}</p>

					</div>
					<!-- /Footer Widget -->

				</div>

				<div class="col-xl-4 col-lg-4 col-md-6 col-sm-12  mb-5">
					<!-- Footer Widget -->
					<div class="footer-widget footer-menu">
						<ul class="tableStyle">
							<li class="mb-cur"><a routerLinkActive="active" class="aP pointer"
									[routerLink]="'/' + app?.languageCode +'/terms'">{{
									'Footer.termsAnd' | translate }}</a></li>
							<li class="mb-cur"><a routerLinkActive="active" class="aP my-5 pointer"
									[routerLink]="'/' + app?.languageCode +'/policy'">{{
									'Footer.Privacy' |
									translate
									}}</a></li>
							<!-- <li class="mb-cur"><a routerLinkActive="active" class="aP"
									[routerLink]="'/' + app?.languageCode +'/Register'">{{
									'Footer.faqs' |
									translate
									}}</a></li> -->
							<li><a routerLinkActive="active" class="aP"
									[routerLink]="'/' + app?.languageCode +'/contact-us'">{{
									'NavBar.pitchAn' |
									translate
									}}</a></li>
						</ul>
					</div>
					<!-- /Footer Widget -->
				</div>

				<div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 ">
					<!-- Footer Widget -->
					<div class="footer-widget footer-menu">
						<ul class="tableStyle">
							<li class="mb-cur"><a class="aP" [routerLink]="'/' + app?.languageCode +'/home'"
									routerLinkActive="active">{{
									'NavBar.HOME' | translate }}</a></li>
							<li class="mb-cur"><a class="aP my-5" [routerLink]="'/' + app?.languageCode +'/industry'"
									routerLinkActive="active">{{ 'NavBar.INDUSTRIES' |
									translate
									}}</a></li>
							<li class="mb-cur"><a  style="text-transform: uppercase;" class="aP my-5" [routerLink]="'/' + app?.languageCode +'/portfolio'"
									routerLinkActive="active">{{ 'NavBar.portfolio' |
									translate
									}}</a></li>
							<li class="mb-cur"><a class="aP" [routerLink]="'/' + app?.languageCode +'/strategy'"
									routerLinkActive="active">{{ 'NavBar.invest' |
									translate
									}}</a></li>
							<li><a class="aP" [routerLink]="'/' + app?.languageCode +'/blogs'"
									routerLinkActive="active">{{ 'NavBar.BLOGS' |
									translate
									}}</a></li>
						</ul>
					</div>
					<!-- /Footer Widget -->
				</div>


			</div>
		</div>
	</div>
	<!-- /Footer Top -->

	<!-- Footer Bottom -->
	<div class="footer-bottom">
		<div class="container">

			<!-- Copyright -->
			<div class="copyright" style="direction: ltr !important;">
				<div class="row">
					<div class="col-md-6 col-lg-6 col-12">
						<div class="copyright-text">
							<!-- © {{currentYear}} {{ 'footer.fewfwewe' | translate }} -->
							<p (click)="routeToTechznone()" class="mb-0 footerTechnzone">Powered By Technzone | {{currentYear}}</p>
						</div>
					</div>
					<div class="col-md-6 col-lg-6">

						<!-- Copyright Menu -->
						<!-- <div class="copyright-menu">
							<ul class="policy-menu res-last-line">
								<li><a href="javascript:void(0);" [routerLink]="'/' + app?.languageCode +'/terms'">{{
										'footer.Terms and Conditions' | translate }}</a></li>
								<li class="mr-15"><a href="javascript:void(0);"
										[routerLink]="'/' + app?.languageCode +'/terms'">{{ 'footer.Policy' | translate
										}}</a></li>
							</ul>
						</div> -->
						<!-- /Copyright Menu -->

					</div>
				</div>
			</div>
			<!-- /Copyright -->

		</div>
	</div>
	<!-- /Footer Bottom -->
</footer>
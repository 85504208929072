import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InternationalizationComponent } from './internationalization/internationalization.component';

const routes: Routes = [
  { path: '', redirectTo: `${localStorage.Language ? localStorage.Language : 'en'}/home`, pathMatch: 'full' },

  {
    path: ':languageCode',
    component: InternationalizationComponent,
    children: [
      {
        path: 'home',
        loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
        data: { title: { en: '7-Magna | Home', ar: '7-Magna | الرئيسية' } }
      },
      {
        path: 'terms',
        loadChildren: () =>
          import('./common/terms/terms.module').then((m) => m.TermsModule),
        data: { title: { en: '7-Magna | Terms And Conditions', ar: '7-Magna |  الأحكام والشروط' } }
      },
      {
        path: 'blogs',
        loadChildren: () =>
          import('./blogs/blogs.module').then((m) => m.BlogsModule),
        data: { title: { en: '7-Magna | Blogs', ar: '7-Magna | مدونات' } }
      },
      {
        path: 'strategy',
        loadChildren: () =>
          import('./strategy/strategy.module').then((m) => m.StrategyModule),
        data: { title: { en: '7-Magna | Strategy', ar: '7-Magna | إستراتيجية' } }
      },
      {
        path: 'terms',
        loadChildren: () =>
          import('./common/terms/terms.module').then((m) => m.TermsModule),
        data: { title: { en: '7-Magna | Terms And Conditions', ar: '7-Magna | الأحكام والشروط' } }
      },
      {
        path: 'policy',
        loadChildren: () =>
          import('./common/policy/policy.module').then((m) => m.PolicyModule),
        data: { title: { en: '7-Magna | Privacy Policy', ar: '7-Magna | سياسة الخصوصية' } }
      },
      {
        path: 'industry',
        loadChildren: () =>
          import('./industry/industry.module').then((m) => m.IndustryModule),
        data: { title: { en: '7-Magna | Industry', ar: '7-Magna | الصناعات' } }
      },
      {
        path: 'portfolio',
        loadChildren: () =>
          import('./portfolio/portfolio.module').then((m) => m.PortfolioModule),
        data: { title: { en: '7-Magna | Portfolio', ar: '7-Magna | محفظتنــــــا' } }
      },
      {
        path: 'contact-us',
        loadChildren: () =>
          import('./contact-us/contact-us.module').then((m) => m.ContactUsModule),
        data: { title: { en: '7-Magna | Contact Us', ar: '7-Magna | تواصل معنا' } }
      },
      
      { path: '**', redirectTo: 'home', pathMatch: 'full' },
    ]
  },
  { path: '**', redirectTo: `${localStorage.Language ? localStorage.Language : 'en'}/home`, pathMatch: 'full' },
  {
    path: '**',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'legacy',
      initialNavigation: 'enabledBlocking'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }

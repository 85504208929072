import { HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "src/app/app.service";
import { environment } from "src/environments/environment";
import { isPlatformBrowser, isPlatformServer } from '@angular/common';



@Injectable({
    providedIn: "root"
})
export class AppInterceptor implements HttpInterceptor {

    // UserData: any = JSON.parse(localStorage.getItem("UserData"));

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private app: AppService,
        private router: Router
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): any {
        
        let langServerSideCode = this?.router?.url?.includes('/ar/') ? 'ar' : 'en';
        const LangCode = isPlatformBrowser(this.platformId) ? this?.app?.languageCode : langServerSideCode

        if (isPlatformBrowser(this.platformId)) {
            const clonedRequest = request.clone({
                headers: new HttpHeaders({
                    "Authorization": `Bearer ${localStorage?.getItem(environment?.appName)}`,
                    'Access-Control-Allow-Origin': '*',
                    'Accept-language': LangCode,
                    'No-Auth': 'True'
                })
            });
            // console.warn("Browser",clonedRequest)
            return next.handle(clonedRequest)
        } else if (isPlatformServer(this.platformId)) {
            const clonedRequest = request.clone({
                headers: new HttpHeaders({
                    'Access-Control-Allow-Origin': '*',
                    'No-Auth': 'True',
                    'Accept-language': LangCode,
                })
            });
            return next.handle(clonedRequest)
        }
    }
}
<div class="light fixed-top">
    <div class="purpleBg" *ngIf="purpleBar" data-aos="fade-down" data-aos-duration="800">
        <div class="container h-100">
            <div class="d-flex justify-content-between align-items-center h-100">

                <div>

                </div>

                <div>
                    <p class="m-0" [routerLink]="'/' + app?.languageCode+'/contact-us'" (click)="closePurpleBar()">
                        <span class="firstP pointer">
                            {{"NavBar.wantTo"|translate }}
                        </span>
                        <span class="sectionP pointer">
                            {{"NavBar.pitchAn"|translate }}
                        </span>
                    </p>
                </div>

                <div>
                    <img loading="lazy" src="assets/img/xIcon.svg" class="xLogo pointer" (click)="closePurpleBar()" />
                </div>

            </div>
        </div>
    </div>
    <div class="container py-2">
        <nav class="navbar navbar-expand-lg navbar-light">
            <div class="container-fluid">

                <a [routerLink]="'/' + app?.languageCode+'/home'" class="navbar-brand">
                    <img loading="lazy" src="assets/img/navbar/longLogo.svg" class="logoImg" alt="Logo"
                        data-aos="fade-down" data-aos-duration="800" />
                </a>

                <!-- out -->
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                    data-aos="fade-down" data-aos-duration="800" aria-controls="navbarNav" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <!-- out -->


                <ul class="navbar-nav navDisplayOut" data-aos="fade-down" data-aos-duration="800">

                    <li class="nav-item my-2 mx-4">
                        <a class="nav-link" [routerLink]="'/' + app?.languageCode+'/home'"
                            routerLinkActive="active">{{"NavBar.HOME"|translate }}
                        </a>
                    </li>

                    <li class="nav-item my-2 mx-4">
                        <a class="nav-link" [routerLink]="'/' + app?.languageCode+'/industry'"
                            routerLinkActive="active">{{"NavBar.INDUSTRIES"|translate }}
                        </a>
                    </li>

                    <li class="nav-item my-2 mx-4">
                        <a style="text-transform: uppercase;" class="nav-link" [routerLink]="'/' + app?.languageCode+'/portfolio'"
                            routerLinkActive="active">{{"NavBar.portfolio"|translate }}
                        </a>
                    </li>

                    <li class="nav-item my-2 mx-4">
                        <a class="nav-link" [routerLink]="'/' + app?.languageCode+'/strategy'"
                            routerLinkActive="active">{{"NavBar.invest"|translate }}
                        </a>
                    </li>

                    <li class="nav-item my-2 mx-4">
                        <a class="nav-link" [routerLink]="'/' + app?.languageCode+'/blogs'"
                            routerLinkActive="active">{{"NavBar.BLOGS"|translate }}
                        </a>
                    </li>


                </ul>

                <ul class="navbar-nav navDisplayOut" data-aos="fade-down" data-aos-duration="800">
                    <img loading="lazy" src="assets/img/navbar/translate.svg" class="w-h-24 mx-1 pointer"
                        id="dropdownMenuButton1" data-bs-toggle="dropdown" />
                    <div class="dropdown">
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><a class="dropdown-item pointer" (click)="setLanguage('en')">English</a></li>
                            <li><a class="dropdown-item pointer" (click)="setLanguage('ar')">العربية</a></li>
                        </ul>
                    </div>
                </ul>


                <!-- out -->
                <div class="collapse navbar-collapse navDisplay" id="navbarNav">

                    <ul class="navbar-nav">

                        <li class="nav-item my-2 mx-4">
                            <a data-aos="fade-down" data-aos-duration="800" class="nav-link"
                                [routerLink]="'/' + app?.languageCode+'/home'"
                                routerLinkActive="active">{{"NavBar.HOME"|translate }}
                            </a>
                        </li>
                        
                        <li class="nav-item my-2 mx-4">
                            <a data-aos="fade-down" data-aos-duration="800" class="nav-link"
                                [routerLink]="'/' + app?.languageCode+'/industry'"
                                routerLinkActive="active">{{"NavBar.INDUSTRIES"|translate }}
                            </a>
                        </li>

                        <li class="nav-item my-2 mx-4">
                            <a data-aos="fade-down" data-aos-duration="800" class="nav-link"
                                [routerLink]="'/' + app?.languageCode+'/portfolio'"
                                routerLinkActive="active">{{"NavBar.portfolio"|translate }}
                            </a>
                        </li>

                        <li class="nav-item my-2 mx-4">
                            <a data-aos="fade-down" data-aos-duration="800" class="nav-link"
                                [routerLink]="'/' + app?.languageCode+'/strategy'"
                                routerLinkActive="active">{{"NavBar.invest"|translate }}
                            </a>
                        </li>


                        <li class="nav-item my-2 mx-4">
                            <a data-aos="fade-down" data-aos-duration="800" class="nav-link"
                                [routerLink]="'/' + app?.languageCode+'/blogs'"
                                routerLinkActive="active">{{"NavBar.BLOGS"|translate }}
                            </a>
                        </li>


                    </ul>

                    <ul class="navbar-nav mx-3">
                        <img loading="lazy" src="assets/img/navbar/translate.svg" class="w-h-24 mx-1 pointer"
                            id="dropdownMenuButton1" data-bs-toggle="dropdown" />
                        <div class="dropdown">
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><a class="dropdown-item pointer" (click)="setLanguage('en')">English</a></li>
                                <li><a class="dropdown-item pointer" (click)="setLanguage('ar')">العربية</a></li>
                            </ul>
                        </div>
                    </ul>
                </div>
                <!-- out -->

            </div>
        </nav>
    </div>
</div>
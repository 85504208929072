<div class="main-wrapper">

	<div *ngIf="app.isLoading && app?.isPlatformBrowserVar" class="d-flex justify-content-center">
		<img [ngClass]="this.app?.getUserLanguage() == 'en' ? 'rl' : 'lr'" class="imgLoading" src="./assets/img/spinner1.gif" alt="loading" />
	</div>


	<app-headerboot *ngIf="app?.isPlatformBrowserVar"></app-headerboot>

	<main>
		<router-outlet></router-outlet>
	</main>

	<app-footer *ngIf="app?.isPlatformBrowserVar"></app-footer>
</div>
import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-headerboot',
  templateUrl: './headerboot.component.html',
  styleUrls: ['./headerboot.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class HeaderbootComponent implements OnInit {

  selectedLang

  purpleBar : boolean = false

  constructor(
    public app: AppService,
    public router: Router,
    public appCompon: AppComponent,
    public route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.selectedLang = this?.router?.url?.includes('/ar/') ? 'ar' : 'en';
    }, 100);
    setTimeout(() => {
      this.purpleBar = true
    }, 2000);
    // this.selectedLang = this!.app!.getUserLanguage()
  }

  closePurpleBar(){
    this.purpleBar = false
  }




  setLanguage(e) {
    let x = this.app.getUserLanguage()

    if (e == 'en' && x != 'en') {
      this.router.navigateByUrl(this.router.url.replace('ar', 'en'));
      localStorage.setItem("Language", 'en');
      localStorage.setItem("Direction", 'ltr');
      if (isPlatformBrowser(this.platformId)) {
        setTimeout(() => {
          window.location.reload();
        }, 100);
      }
    } else if (e == 'ar' && x != 'ar') {
      this.router.navigateByUrl(this.router.url.replace('en', 'ar'));
      localStorage.setItem("Language", 'ar');
      localStorage.setItem("Direction", 'rtl');
      if (isPlatformBrowser(this.platformId)) {
        setTimeout(() => {
          window.location.reload();
        }, 100);
      }
    }
  }

}
